/* global Content */
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@components/Button';

import { Inputs } from './Inputs';
import * as styles from './styles.module.scss';

interface FormProps extends Content.FormData {
	submitText: string;
	onSubmit (data: any): void;
}

export const Form: React.FunctionComponent<FormProps> = ({ onSubmit, inputs, children, submitText }) => {
	const methods = useFormContext();

	return (
		<form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
			{children}
			{inputs && <Inputs inputs={inputs} />}
			<Button role="primary" type="submit">{submitText}</Button>
		</form>
	);
};

import * as React from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';

import * as styles from './styles.module.scss';

interface TextInputProps {
	type: string;
	name: string;
	placeholder?: string;
	title?: string;
	pattern?: string;

	inputRules?: RegisterOptions;
}

export const TextInput: React.FunctionComponent<TextInputProps> = ({ type, inputRules, placeholder, name, title, pattern }) => {
	const methods = useFormContext();

	return (
		<input
			{...methods.register(name, inputRules)}
			type={type}
			placeholder={placeholder}
			name={name}
			title={title}
			pattern={pattern}
			className={styles.textInput}
		/>
	);
};

/* global Content */
/* eslint-disable react/jsx-no-useless-fragment */
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { SVGIcon } from '@components/SVGIcon';
import { TextInput } from '@components/TextInput';

import * as styles from './styles.module.scss';

interface InputsProps {
	inputs: Content.InputData[];
}

export const Inputs: React.FunctionComponent<InputsProps> = ({ inputs }) => {
	const methods = useFormContext();
	const renderMessageCallback = React.useCallback(({ message }) => message && <div className={clsx(styles.inputsPopover, styles.warning)}>{message}</div>, []);
	const renderedInputs = inputs.map(({ required, name, type, placeholder, title, label }) => {
		let input;
		const inputRules = {
			required
		};
		switch (type) {
			case 'checkbox':
				input = (
					<label className={styles.inputsCheckbox}>
						<input
							{...methods.register(name, inputRules)}
							name={name}
							type={type}
							title={title}
						/>
						<div>
							<SVGIcon name="check" />
						</div>
						<p>
							{label}
						</p>
					</label>
				);
				break;
			case 'textarea':
				input = (
					<textarea
						{...methods.register(name, inputRules)}
						className={styles.inputsTextArea}
						name={name}
						placeholder={placeholder}
						title={title}
					/>
				);
				break;
			case 'hidden':
				input = (
					<input
						{...methods.register(name, inputRules)}
						type="hidden"
						value="1"
						name={name}
						placeholder={placeholder}
						title={title}
					/>
				);
				break;
			default:
				input = (
					<TextInput
						name={name}
						type={type}
						placeholder={placeholder}
						title={title}
						inputRules={inputRules}
					/>);
		}

		return (
			<div
				key={`${placeholder}-${name}`}
				className={styles.inputsText}
			>
				<ErrorMessage
					errors={methods.formState.errors}
					name={name}
					render={renderMessageCallback}
				/>
				{input}
			</div>
		);
	});

	// @todo check if this can be resolved
	// this is necessary because of this https://github.com/DefinitelyTyped/DefinitelyTyped/issues/20356
	return (
		<React.Fragment>
			{renderedInputs}
		</React.Fragment>
	);
};

/* global Content */
import * as React from 'react';

import { Header } from '@components/Header';
import { Wizard } from '@components/Wizard';
import { Wrapper } from '@components/Wrapper';

import * as styles from './styles.module.scss';

interface WizardTemplateProps {
	pageContext: Content.WizardData;
}

const WizardTemplate: React.FunctionComponent<WizardTemplateProps> = ({ pageContext }) => (
	<div className={styles.wizard}>
		<Header onlyColoredLogo={true} />
		<Wrapper>
			<Wizard {...pageContext} />
		</Wrapper>
	</div>
);

export default WizardTemplate;

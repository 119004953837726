
import clsx from 'clsx';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { SVGIcon } from '@components/SVGIcon';

import * as styles from './styles.module.scss';

interface OptionProps {
	iconName?: any;
	multiSelect?: boolean;

	name: string;
	value: string;
}

export const Option: React.FunctionComponent<OptionProps> = ({ name, value, multiSelect, iconName }) => {
	const type = multiSelect ? 'checkbox' : 'radio';
	const methods = useFormContext();
	const activeField = methods.watch(name);
	const [active, setActive] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (activeField === value) {
			setActive(true);
		}
		else {
			setActive(false);
		}
	}, [activeField, value]);

	return (
		<label className={clsx(styles.pageOption, active && styles.active)}>
			<input {...methods.register(name)} type={type} name={name} value={value} />
			{iconName && <SVGIcon name={iconName} />}
			<div>{multiSelect ? name : value}</div>
		</label>
	);
};
Option.displayName = 'Option';

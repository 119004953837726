/* global Content */
import { Router, navigate } from '@reach/router';
import { send } from 'emailjs-com';
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { Page } from './Page';

interface WizardProps extends Content.WizardData {}

export const Wizard: React.FunctionComponent<WizardProps> = ({ pages, funnelRoute }) => {
	const [formData, setFormdata] = React.useState<{ [x: string]: string; }>({});
	const [pageIndex, setPageIndex] = React.useState<number>(0);
	const handleSubmit = React.useCallback(async (data) => {
		const newFormData = {
			...formData,
			...data
		};
		setFormdata(newFormData);
		if ((pageIndex + 1) < pages.length) {
			await navigate(`${funnelRoute}${pages[pageIndex + 1].path}`);
		}
	}, [formData, funnelRoute, pageIndex, pages]);
	const handleLoad = React.useCallback((index) => setPageIndex(index), []);
	const methods = useForm({
		defaultValues: formData
	});

	React.useEffect(() => {
		if ('send' in formData) {
			// eslint-disable-next-line no-console
			void send('service_h68p495', 'template_btalk1i', formData, 'user_dPSqK6M23LS4SAjuZ8Lhm').then((result) => console.log(result), (error) => console.log(error));
		}
	}, [formData]);

	return (
		<FormProvider {...methods}>
			<Router basepath={funnelRoute}>
				{pages.map((page, index) => page.path && <Page key={page.path} index={index} {...page} onSubmit={handleSubmit} onLoad={handleLoad} />)}
			</Router>
		</FormProvider>
	);
};

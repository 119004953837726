/* eslint-disable react/jsx-no-useless-fragment */
/* global Content */
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import ReactPlayer from 'react-player';

import * as styles from './styles.module.scss';

interface MediaProps {
	media: (Content.ImageObject|Content.VideoObject)[];
}

export const Media: React.FunctionComponent<MediaProps> = ({ media }) => {
	const files = useStaticQuery(graphql`
		query {
			allFile {
				edges {
					node {
						name
						childImageSharp{
							gatsbyImageData(layout: CONSTRAINED)
						}
					}
				}
			}
		}
	`);

	const renderedMediaElements = media.map((entry) => {
		if ('imageName' in entry) {
			const source = files.allFile.edges.find((imageEntry: any) => imageEntry.node.name === entry.imageName).node.childImageSharp.gatsbyImageData;
			return source && <GatsbyImage key={entry.imageName} image={source} alt="" />;
		}
		return (
			<div key={entry.url} className={styles.pageVideo}>
				<ReactPlayer
					className={styles.player}
					url={entry.url}
					width="100%"
					height="100%"
				/>
			</div>
		);
	});

	return (
		<React.Fragment>
			{renderedMediaElements}
		</React.Fragment>
	);
};

/* global Content */
import * as React from 'react';

import { Form } from '@components/Form';

import { Media } from './Media';
import { Option } from './Option';
import * as styles from './styles.module.scss';

interface PageProps extends Content.WizardPage {
	index: number;

	onSubmit (data: any): void;
	onLoad (index: number): void;
}

export const Page: React.FunctionComponent<PageProps> = ({ onLoad, headline, subline, text, index, media, multiSelect, options, inputs, ...restProps }) => {
	React.useEffect(() => onLoad(index), [index, onLoad]);

	return (
		<div className={styles.pageWrapper}>
			{headline && <h1>{headline}</h1>}
			{subline && <h2>{subline}</h2>}
			{text && <p>{text}</p>}
			{(options || inputs) && (
				<Form inputs={inputs} {...restProps}>
					{options && (
						<div className={styles.pageOptions}>
							{options.map((data) => <Option key={`${data.name}-${data.value}`} {...data} multiSelect={multiSelect} />)}
						</div>
					)}
				</Form>
			)}
			{media && <Media media={media} />}
		</div>
	);
};
